import './styles/main.scss';

import * as BackgroundCheck from 'background-check/background-check';
import imagesLoaded from 'imagesloaded';

let currentPage: number|null;
let lastPage: number|null;

let solutionList: HTMLDivElement|null;
let solutionPagination: HTMLDivElement|null;
let solutionLoadMore: HTMLDivElement|null;
let solutionLoadMoreButton: HTMLButtonElement|null;

export function init() {
    document.addEventListener("DOMContentLoaded", () => {
        console.info('shift.how');

        processMenu();
        
        processOffcanvas();

        // Check image background behind text
        BackgroundCheck.init({
            targets: '.solution-title, .article-title',
            images: '.solution-img, .article-img',
            minOverlap: 20,
            windowEvents: false,
            threshold: 54
        });

        solutionList = document.getElementById('solution-list') as HTMLDivElement;

        // Hide pagination if JS is enabled
        solutionPagination = document.getElementById('solution-pagination') as HTMLDivElement;
        if (solutionPagination != null) {
            solutionPagination.classList.add('visually-hidden');
        }

        // If we have a loadMoreButton, show it and enable a scroll listener
        solutionLoadMore = document.getElementById('solution-load-more') as HTMLDivElement;
        
        if (solutionLoadMore != null) {
            solutionLoadMoreButton = solutionLoadMore.getElementsByTagName('button')[0];
            solutionLoadMoreButton.addEventListener('click', () => loadMoreSolutions());
            solutionLoadMoreButton.classList.remove('invisible');

            // if we load more if we scrolled to the bottom of the page 
            window.addEventListener("scroll", function () {
                const scrollPosition = window.scrollY;
                const windowHeight = window.innerHeight;
                const bodyHeight = document.body.offsetHeight;
                const currentPage = parseInt(solutionLoadMoreButton!.dataset.currentPage);

                // Check if the user has scrolled to the bottom of the page and load more for the first three pages
                if (currentPage < 4 && scrollPosition + windowHeight >= bodyHeight - 100) {
                    loadMoreSolutions();
                }
            });
        }
    });

}

const processMenu = function() {
    const menuToggle = document.getElementById('menu-toggle');
    const mainMenu = document.getElementById('main-menu');

    menuToggle!.addEventListener('click', () => {
        const shown = mainMenu!.classList.toggle('show');
        menuToggle!.ariaExpanded = String(shown);
    });

    document.body.addEventListener('keyup', (event)  => {
        if (event.key === 'Escape') {
            mainMenu!.classList.remove('show');
            menuToggle!.ariaExpanded = String(false);
        }
    });
}

const processOffcanvas = function() {
    const backdrop = document.createElement('div');
    backdrop.classList.add('offcanvas-backdrop');
    document.body.append(backdrop);

    const toggleElements = document.querySelectorAll<HTMLAnchorElement>('[data-toggle=offcanvas]');
    toggleElements.forEach((toggleElement) => {
        toggleElement.addEventListener('click', () => {
            const target = toggleElement.dataset.target;
            if (target) {
                const toggledElement = document.querySelector(target);
                if (toggledElement) {
                    toggleElement.ariaExpanded = String(true);
                    toggledElement.classList.add('show');
                    backdrop.classList.add('show');
                }
            }
        })
    })
    backdrop.addEventListener('click', () => {
        backdrop.classList.remove('show');
        toggleElements.forEach((toggleElement) => {
            const target = toggleElement.dataset.target;
            if (target) {
                const toggledElement = document.querySelector(target);
                if (toggledElement) {
                    toggleElement.ariaExpanded = String(false);
                    toggledElement.classList.remove('show');
                }
            }
        });
    })

    const dismissElements = document.querySelectorAll<HTMLAnchorElement>('[data-dismiss=offcanvas]');
    dismissElements.forEach((dismissElement) => {
        dismissElement.addEventListener('click', () => {
            const target = dismissElement.dataset.target;
            if (target) {
                const toggledElement = document.querySelector(target);
                if (toggledElement) {
                    backdrop.classList.remove('show');
                    toggledElement.classList.remove('show');
                    const toggleElements = document.querySelectorAll('[data-toggle=offcanvas][data-target=\''+target+'\']');
                    console.log(toggleElements);
                    toggleElements.forEach((toggleElement) => toggleElement.ariaExpanded = String(false));
                }
            }
       })
    })
}

export function onSolutionListUpdated(data: { url: string, append: boolean}): void {
    // Update the URL
    if (data.append === false) {
        history.pushState(null, '', data.url);
        const canonical = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
        if (canonical !== null) {
            canonical.href = data.url;
        }
    }
}

export function loadMoreSolutions() {
    if (solutionLoadMore != null) {
        solutionLoadMoreButton = solutionLoadMore.getElementsByTagName('button')[0];

        if (solutionLoadMoreButton) {
            if (solutionLoadMoreButton.classList.contains('prevent-loading')) {
                // Don't load more for now as the previous request has not completed yet
            } else {
                solutionLoadMoreButton.classList.add('prevent-loading');

                if (solutionLoadMoreButton.dataset.currentPage != null) {
                    currentPage = parseInt(solutionLoadMoreButton.dataset.currentPage);
                }
                if (solutionLoadMoreButton.dataset.lastPage != null) {
                    lastPage = parseInt(solutionLoadMoreButton.dataset.lastPage);
                }

                const nextPage = currentPage! + 1;

                setTimeout(() => {
                    solutionLoadMoreButton!.classList.add('invisible');
                    oc.request(this, 'onSolutionListUpdate', {
                        data: { page: nextPage, append: true },
                        afterUpdate: () => {
                            if (nextPage! < lastPage!) {
                                imagesLoaded(solutionList!).once('always', () => {
                                    solutionLoadMoreButton = solutionLoadMore!.getElementsByTagName('button')[0];
                                    solutionLoadMoreButton.classList.remove('prevent-loading');
                                    if (nextPage > 3) {
                                        solutionLoadMoreButton.classList.remove('invisible');
                                    }
                                    solutionLoadMoreButton.addEventListener('click', () => loadMoreSolutions());
                                });
                            }
                        },
                        progressBar: true
                    });
                }, 500);
            }
        }
    }
}