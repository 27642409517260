import './styles/main.scss';

import * as BackgroundCheck from 'background-check/background-check';
import imagesLoaded from 'imagesloaded';

// let currentPage: number|null;
// let lastPage: number|null;

let solutionList: HTMLDivElement|null;
let solutionPagination: HTMLDivElement|null;
let solutionLoadMore: HTMLDivElement|null;
let solutionLoadMoreButton: HTMLAnchorElement|null;

export function init() {
    document.addEventListener("DOMContentLoaded", () => {
        console.info('shift.how');

        processMenu();
        
        processTabs();

        processOffcanvas();

        // Check image background behind text
        BackgroundCheck.init({
            targets: '.solution-title, .article-title',
            images: '.solution-img, .article-img',
            minOverlap: 20,
            windowEvents: false,
            threshold: 54
        });

        solutionList = document.getElementById('solution-list') as HTMLDivElement;

        // Hide pagination if JS is enabled
        solutionPagination = document.getElementById('solution-pagination') as HTMLDivElement;
        if (solutionPagination != null) {
            solutionPagination.classList.add('visually-hidden');
        }

        // If we have a loadMoreButton, show it and enable a scroll listener
        solutionLoadMore = document.getElementById('solution-load-more') as HTMLDivElement;

        if (solutionLoadMore != null) {
            solutionLoadMoreButton = solutionLoadMore.getElementsByTagName('a')[0];
            if (solutionLoadMoreButton) {
                solutionLoadMoreButton.classList.remove('invisible');
            }

            // // if we load more if we scrolled to the bottom of the page 
            // window.addEventListener("scroll", function () {
            //     if (solutionLoadMoreButton) {
            //         const scrollPosition = window.scrollY;
            //         const windowHeight = window.innerHeight;
            //         const bodyHeight = document.body.offsetHeight;
            //         const currentPage = parseInt(solutionLoadMoreButton!.dataset.currentPage!);

            //         // Check if the user has scrolled to the bottom of the page and load more for the first three pages
            //         if (currentPage < 4 && scrollPosition + windowHeight >= bodyHeight - 100) {
            //             // oc.request(solutionLoadMoreButton);
            //             // loadMoreSolutions();
            //         }
            //     }
            // });
        }
    });
}

// Fullscreen menu
const processMenu = function() {
    const menuToggle = document.getElementById('menu-toggle');
    const mainMenu = document.getElementById('main-menu');

    menuToggle!.addEventListener('click', () => {
        const shown = mainMenu!.classList.toggle('show');
        menuToggle!.ariaExpanded = String(shown);
    });

    document.body.addEventListener('keyup', (event)  => {
        if (event.key === 'Escape') {
            mainMenu!.classList.remove('show');
            menuToggle!.ariaExpanded = String(false);
        }
    });
}

// Sidemenu (Filter) 
const processOffcanvas = function() {
    const backdrop = document.createElement('div');
    backdrop.classList.add('offcanvas-backdrop');
    document.body.append(backdrop);

    const toggleElements = document.querySelectorAll<HTMLAnchorElement>('[data-toggle=offcanvas]');
    toggleElements.forEach((toggleElement) => {
        toggleElement.addEventListener('click', () => {
            const target = toggleElement.dataset.target;
            if (target) {
                const toggledElement = document.querySelector(target);
                if (toggledElement) {
                    toggleElement.ariaExpanded = String(true);
                    toggledElement.classList.add('show');
                    backdrop.classList.add('show');
                }
            }
        })
    })
    backdrop.addEventListener('click', () => {
        backdrop.classList.remove('show');
        toggleElements.forEach((toggleElement) => {
            const target = toggleElement.dataset.target;
            if (target) {
                const toggledElement = document.querySelector(target);
                if (toggledElement) {
                    toggleElement.ariaExpanded = String(false);
                    toggledElement.classList.remove('show');
                }
            }
        });
    })

    const dismissElements = document.querySelectorAll<HTMLAnchorElement>('[data-dismiss=offcanvas]');
    dismissElements.forEach((dismissElement) => {
        dismissElement.addEventListener('click', () => {
            const target = dismissElement.dataset.target;
            if (target) {
                const toggledElement = document.querySelector(target);
                if (toggledElement) {
                    backdrop.classList.remove('show');
                    toggledElement.classList.remove('show');
                    const toggleElements = document.querySelectorAll('[data-toggle=offcanvas][data-target=\''+target+'\']');
                    toggleElements.forEach((toggleElement) => toggleElement.ariaExpanded = String(false));
                }
            }
       })
    })
}

const processTabs = function() {

    const tabButtons = document.querySelectorAll<HTMLElement>('[data-toggle="tab"]');

    tabButtons.forEach((button) => {
        button.addEventListener('click', () => {
            tabButtons.forEach((button) => {
                button.ariaSelected = 'false';
                button.classList.remove('active');
                document.querySelector(button.dataset.target!)!.classList.remove('active');;
            });

            button.ariaSelected = 'true';
            button.classList.add('active');
            document.querySelector(button.dataset.target!)!.classList.add('active');;
        });
    });
}

export function onSolutionListUpdated(data: { url: string, counts: { [id: string]: number; }, append: boolean}): void {
    const howButton = document.getElementById('how-tab') as HTMLButtonElement;
    if (howButton) {
        let counter = howButton.querySelector('i');
        if (data.counts.how === 0) {
            if (counter) {
                counter.remove();
            }
        } else {
            if (!counter) {
                counter = document.createElement('i');
                howButton.appendChild(counter);
            }
            counter.textContent = data.counts.how.toString();
        }
    }

    const whatButton = document.getElementById('what-tab') as HTMLButtonElement;
    if (whatButton) {
        let counter = whatButton.querySelector('i');
        if (data.counts.what === 0) {
            if (counter) {
                counter.remove();
            }
        } else {
            if (!counter) {
                counter = document.createElement('i');
                whatButton.appendChild(counter);
            }
            counter.textContent = data.counts.what.toString();
        }
    }

    const whereButton = document.getElementById('where-tab') as HTMLButtonElement;
    if (whereButton) {
        let counter = whereButton.querySelector('i');
        if (data.counts.where === 0) {
            if (counter) {
                counter.remove();
            }
        } else {
            if (!counter) {
                counter = document.createElement('i');
                whereButton.appendChild(counter);
            }
            counter.textContent = data.counts.where.toString();
        }
    }

    // Update the URL
    if (data.append === false) {
        history.pushState(null, '', data.url);
        const canonical = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
        if (canonical !== null) {
            canonical.href = data.url;
        }
    }

    // If we have a loadMoreButton, reenable the handler once things have loaded
    imagesLoaded(solutionList!).once('always', () => {
        if (solutionLoadMore != null) {
            solutionLoadMoreButton = solutionLoadMore.getElementsByTagName('a')[0];
            if (solutionLoadMoreButton) {
                solutionLoadMoreButton!.classList.remove('invisible');
            }
        }
    });
}